import React, {useEffect, useState, useCallback} from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Grid, Button, Box, Typography, Autocomplete, TextField, Skeleton,
         FormControl, InputLabel, Select, MenuItem, Chip, OutlinedInput,
         Checkbox, ListItemText
 } from '@mui/material';
import httpClient from "../httpClient"
import dayjs from 'dayjs';
import logo from '../assets/the_burn_secondary_logo_fullcolor_RGB.png'
import eventPic from '../assets/1.PNG'



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, classesSelected, theme) {
    return {
      fontWeight:
      classesSelected.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }


function RowItem ({item, handleRedirecting}) {
    return (
      <Grid container item key={item.id} padding justifyContent={'center'}>
        <Grid item xs={3} sm={2} lg={1}>
          <Box
            component="img"
            sx={{
                height: 75,
                width: 'auto',
                maxWidth: 75,
                borderRadius: '50%'
            }}
            alt='pic'
            src={item.image_url || logo}
          />
        </Grid>
        <Grid item xs={5} sm={4} md={3}>
          <Typography variant='subtitle1' fontWeight={'bold'}>
            {dayjs(item.class_time).format('ddd - h:mmA - MMM D')}
          </Typography>
          <Typography varient='subtitle2' fontWeight={'bold'}>
            {item.title}
          </Typography>
          <Typography variant='overline'>
            {item.instructor_name}
          </Typography>
        </Grid>
        <Grid paddingTop={4}>
          <Button variant='contained' onClick={() => handleRedirecting(item)}>
            Register
          </Button>
        </Grid>
      </Grid>
    )
  }


function EventRow () {
  return (
    <Grid container padding>
      <Grid item xs={12} sm={5} lg={4} padding>
        <Box
          component="img"
          sx={{
              height: 'auto',
              width: 'auto',
              maxWidth: '90%',
              borderRadius: '25px',
              padding: 2
          }}
          alt='pic'
          src={eventPic}
        />
      </Grid>
      <Grid item xs={12} sm={7} lg={8} padding>
        <Grid container item>
            <Grid item xs={12} textAlign={'center'}>
                <Typography variant="h5" component="h2" fontWeight={'bold'}>The Grand Opening Challenge is Here!</Typography>
            </Grid>
            <Grid item={12} padding>
                <Typography variant="subtitle1" component="h2" fontWeight={'bold'}>Let's Get Burning</Typography>
                <Typography variant="body2" color="textSecondary">
                  Get ready to challenge yourself this October with fun activities, new classes, and exciting community events at The Burn! Whether it’s bringing a friend, trying a new class, or attending our special Bubbles & Burn Barre Event on 11/1.  Every square is a chance to is a chance to win big and stay fit!
                </Typography>
            </Grid>
            <Grid item={12} padding>
              <Typography variant="body2" color="textSecondary">
                Pick up your bingo cards at the studio front desk! Complete 1 bingo for a prize, a second bingo to be entered into our grand prize giveaway! Let’s BURN together this fall!
              </Typography>
            </Grid>
            <Grid item padding xs={12}>
                <Typography variant="subtitle2" component="h2" fontWeight={'bold'}>
                    BINGO - RAFFLE - PRIZES - FREE CLASSES
                </Typography>
            </Grid>
        </Grid>
        
      </Grid>
    </Grid>
  )
}


export default function ClassSchedule() {
    let [searchParams, setSearchParams] = useSearchParams()

  const updateSearchParams = useCallback((key, value) => {
    if (!value || value === '' || value.length === 0) {
      searchParams.delete(key)
    } else {
      searchParams.set(key, value)
    }
    setSearchParams(searchParams)
  }, [searchParams, setSearchParams])

    const [testData, setTestData] = useState([]);
  const [days, setDays] = useState([]);
  const [classes, setClasses] = useState([]);
  const [classesSelected, setClassesSelected] = useState(searchParams.get('classesSelected') ? [searchParams.get('classesSelected')] :[]);
  const [instructors, setInstructors] = useState([]);
  const [instructorSelected, setInstructorSelected] = useState(null);
  const [daysOut, setDaysOut] = useState(7);

  // need to update classSelected to be a search param
    useEffect(() => {
        updateSearchParams('classesSelected', classesSelected)
    }, [classesSelected, updateSearchParams])

    // need to update instructorSelected to be a search param
    useEffect(() => {
        updateSearchParams('instructorSelected', instructorSelected)
    }, [instructorSelected, updateSearchParams])

  const handleRedirect = (item) => {
    window.open(item.redirectUrl, 'mozillaTab');
  }
  // 873 <-- fitspot_id
  const transformData = useCallback((data) => {
    if (!data) return;
    let temp = [];
    let dayTemp = [];
    let tempClasses = [];
    let tempInstructors = [];
    let oddDataStruct = data?.response.data.cache
    let scheduleItems = oddDataStruct.schedule_items
    let employees = oddDataStruct.employees
    let images = oddDataStruct.images
    let regsiter = oddDataStruct.registrables
    scheduleItems.forEach((item) => {
      let employee = employees.find((emp) => emp.id === item.employee_id)
      let image = images.find((img) => img.id === employee?.profile_picture_image_id)
      temp.push({
        id: item.id,
        instructor_name: employee?.first_name + " " + employee?.last_name,
        instructor_id: employee?.id,
        image_url: image?.sources[0].url,
        class_time: item.fs_start_datetime,
        class_date: dayjs(item.fs_start_datetime).format('YYYY-MM-DD'),
        title: item.title,
        redirectUrl: regsiter.find((reg) => reg.id === item.id)?.share_url
      })
      if (!dayTemp.includes(dayjs(item.fs_start_datetime).format('YYYY-MM-DD'))) {
        dayTemp.push(dayjs(item.fs_start_datetime).format('YYYY-MM-DD'))
      }
      if (!tempClasses.includes(item.title)) {
        tempClasses.push(item.title)
      }
      if (!tempClasses.includes(item.title.slice(0, -3))) {
        tempClasses.push(item.title.slice(0, -3))
      }
      if (searchParams.get('classesSelected') && !tempClasses.includes(searchParams.get('classesSelected'))) {
        tempClasses.push(searchParams.get('classesSelected'))
        }
        if (!tempInstructors.includes(employee?.first_name + " " + employee?.last_name)) {
            tempInstructors.push(employee?.first_name + " " + employee?.last_name)
        }
    })
    setTestData(temp);
    setDays(dayTemp);
    setClasses(tempClasses.sort());
    setInstructors(tempInstructors.sort());
  }, [searchParams])

  const theme = useTheme();

    const getGetFitDegreeSchedule = useCallback(async (newDaysOut=null) => {
        let currentDaysOut = newDaysOut || daysOut
        // this should be using server time zone (CST)
        let now = dayjs()
        // convert now to end date by adding daysOut
        let end = now.add(currentDaysOut, 'day')

        let url = `/schedule/item/with-dates/?`
        url += `start_datetime=${now.format('YYYY-MM-DD')}%2005%3A00%3A00&`
        url += `&end_datetime=${end.format('YYYY-MM-DD')}%2004%3A59%3A59&`
        url += `&object_type__IN=%5B1%2C4%2C2%5D&is_cancelled__IN=%5B%220%22%5D&is_cancelled__EQ=0&display_on_app__EQ=1&fitspot_id=873`
        try {
            const resp = await httpClient.get(url)
            setTestData(resp.data)
            transformData(resp.data)
        } catch (e) {
            console.log(e)
        }
    }, [daysOut, transformData])

    const filterData = () => {
        let temp = testData;
        if (classesSelected.length > 0) {
            // want to handle exact match and partial match
            // temp = testData.filter((item) => (classesSelected.includes(item.title) || classesSelected.map((cs) => item.title.includes(cs))))
            temp = testData.filter((item) => classesSelected.some((c) => item.title.includes(c)))
            //temp = testData.some((item) => classesSelected.includes(item.title))
        }
        if (instructorSelected) {
            temp = temp.filter((item) => item.instructor_name === instructorSelected)
        }
        return temp;
    }

    const filterDates = () => {
        let tempData = filterData();
        let temp = [];
        days.forEach((day) => {
            if (tempData.find((item) => item.class_date === day)) {
                temp.push(day)
            }
        })
        return temp;
    }

    useEffect(() => {
        getGetFitDegreeSchedule()
    }, [getGetFitDegreeSchedule])

    const handleDaysOutChange = (e) => {
        setDaysOut(e.target.value)
        getGetFitDegreeSchedule(e.target.value)
    }

    const handleClassesChange = (event) => {
        const {
          target: { value },
        } = event;
        setClassesSelected(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
      };

  return (
    <Grid container>
        <Grid item paddingTop={5}>
            <EventRow />
        </Grid>
        <Grid item xs={12} paddingTop={3} textAlign={"center"}>
            <Typography variant="h3">
                Class Schedule
            </Typography>
        </Grid>
        <Grid container item paddingTop={5} justifyContent={'center'}>
            
            <Grid item xs={12} sm={6} md={3} padding>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Date Range</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={daysOut}
                    label="Date Range"
                    onChange={handleDaysOutChange}
                >
                    <MenuItem value={7}>Next 7 Days</MenuItem>
                    <MenuItem value={14}>Next 14 Days</MenuItem>
                    <MenuItem value={30}>Next 30 Days</MenuItem>
                </Select>
            </FormControl>
            </Grid>
            {classes.length > 0 ? (
                <Grid item xs={12} sm={6} md={3} padding>
                    <FormControl fullWidth>
                        <InputLabel id="class-label">Classes</InputLabel>
                        <Select
                            labelId="multi-class-label"
                            id="multi-class"
                            multiple
                            value={classesSelected}
                            onChange={handleClassesChange}
                            input={<OutlinedInput id="class-chip" label="Classes" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value}/>
                                ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                            >
                        {classes.map((name) => (
                            <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, classesSelected, theme)}
                                >
                                <Checkbox checked={classesSelected.indexOf(name) > -1} />
                                <ListItemText primary={name} />
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                </Grid>
            ) : (
                <Skeleton>
                    <TextField label="SPD" />
                </Skeleton>
            )}
            {classes.length > 0 ? (
                <Grid item xs={12} sm={6} md={3} padding>
                    <Autocomplete
                        key={'instructor'}
                        options={instructors}
                        value={instructorSelected}
                        onChange={(event, newValue) => setInstructorSelected(newValue)}
                        renderInput={(params) => (<TextField {...params} label="Instructors" />)}
                    />
                </Grid>
            ) : (
                <Skeleton>
                    <TextField label="SPD" />
                </Skeleton>
            )}
        </Grid>
        <Grid container item paddingTop={5} key='container'>
            {filterDates().length === 0 ? (
                <Grid item textAlign={'center'} xs={12}>
                    <Typography variant='h6' fontWeight={'bold'}>
                        No classes scheduled with the selected filters.
                    </Typography>
                </Grid>
            ) : (
                <Grid item container>
                    {filterDates().map((day) => (
                        <Grid container item key={day}>
                            <Grid item textAlign={'center'} xs={12} padding>
                                <Typography variant='h6' fontWeight={'bold'}>
                                    {dayjs(day).format('dddd - MMM D')}
                                </Typography>
                            </Grid>
                            {filterData().filter((item) => item.class_date === day).map((item) => (
                                <RowItem item={item} key={item.id} handleRedirecting={(item) => handleRedirect(item)}/>
                            ))}
                        </Grid>
                    ))}
                </Grid>
            )}
            
        </Grid>
    </Grid>
  );
}